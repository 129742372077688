<template>
  <div
    class="fixed top-0 w-full h-screen bg-black bg-opacity-25 z-45"
    aria-hidden="true"
  >
    <div class="md:w-2/3 h-full relative" @click="$emit('closeModal')"></div>
    <div
      class="fixed top-0 right-0 z-45 h-screen w-full md:w-1/3 bg-white border-solid border-t-4 border-primary p-8"
    >
      <div>
        <svg
          class="w-6 h-6 hover:text-red-200 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="$emit('closeModal')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div class="mt-10">
        <h1 class="text-4xl text-primary">{{ $t("publish") }}</h1>
        <form class="mt-6" @submit.prevent="publishToFeed">
          <div class="form-block">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Name</label
            >
            <input
              type="text"
              id="name"
              name="name"
              v-model="name"
              placeholder="Name"
              class="block w-full px-2 py-2 mt-1 text-gray-700 border border-gray-300 shadow-sm rounded-md"
            />
          </div>
          <div class="form-block mt-4">
            <label
              for="message"
              class="block text-sm font-medium text-gray-700"
              >{{ $t("message") }}</label
            >
            <textarea
              class="block w-full px-2 py-2 mt-1 text-gray-700 border border-gray-300 shadow-sm rounded-md"
              name="message"
              v-model="message"
              id="message"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="mt-4 form-block">
            <button type="submit" class="btn-primary">Speichern</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "SidebarPublish",
  emits: ["closeModal"],
  props: ["user", "plan", "week"],
  setup(props, { emit }) {
    const name = ref("Wochenplan KW " + props.week);
    const message = ref("");
    const router = useRouter();

    const publishToFeed = async () => {
      try {
        const response = await window.axios.post("/feed/publish", {
          user: props.user,
          diary: props.plan,
          name: name.value,
          message: message.value,
          week: props.week,
        });

        window.emitter.emit("toggleFlash", {
          message: response.data.message,
          type: response.data.type,
        });
        emit("closeModal");
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    return {
      name,
      message,
      publishToFeed,
    };
  },
};
</script>
