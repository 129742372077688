<template>
  <div
    class="fixed top-0 w-full h-screen bg-black bg-opacity-25 z-50"
    aria-hidden="true"
  >
    <div class="md:w-2/3 h-full relative" @click="$emit('closeDetails')"></div>
    <div
      class="fixed overflow-y-auto top-0 right-0 z-45 h-screen w-full md:w-1/3 bg-white border-solid border-t-4 border-primary p-8"
    >
      <div class="flex items-center justify-between">
        <svg
          class="w-6 h-6 hover:text-red-200 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="$emit('closeDetails')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <button
          class="text-xs text-gray-900 px-3 py-1 rounded bg-red-200 hover:bg-red-500 hover:text-white"
          @click="removeFromDiary"
        >
          {{ $t("delete") }}
        </button>
      </div>
      <div class="mt-10">
        <h1 class="text-4xl text-primary mb-1">{{ details.dish.dish.name }}</h1>
        <h2
          class="text-lg text-gray-400 tracking-wide"
          v-if="details.dish.dish.isReference"
        >
          {{ details.dish.dish.source }}
        </h2>
        <a
          class="text-lg text-gray-400 tracking-wide hover:text-primary hover:underline"
          :href="details.dish.dish.source"
          v-else
          target="_blank"
        >
          {{ details.dish.dish.source }}
        </a>
        <div class="mt-8 flex">
          <div class="mr-6 flex flex-col w-1/3">
            <span class="text-xs text-gray-400">{{ $t("date") }}</span>
            <span class="mt-2 text-gray-900 tracking-wide">
              {{ $t(details.day) }} {{ details.date }}
            </span>
          </div>
          <div class="flex flex-col w-2/3">
            <span class="text-xs text-gray-400">{{ $t("categories") }}</span>
            <div class="flex flex-wrap">
              <span
                class="px-2 text-gray-900 rounded mt-2 text-sm mr-2"
                v-for="category in details.dish.dish.categories"
                :key="category._id"
                :style="{ background: category.color }"
                >{{ category.name }}</span
              >
            </div>
          </div>
        </div>
        <div class="mt-12 flex flex-col">
          <div class="flex items-center justify-between relative">
            <span class="text-xs text-gray-400">{{ $t("note") }}</span>
            <span
              class="cursor-pointer hover:text-primary"
              @click="toggleDropdown"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                />
              </svg>
            </span>
            <div
              v-if="showDropdown"
              class="absolute rounded border z-40 bg-white right-0 mt-24"
            >
              <ul class="w-40">
                <li
                  class="py-2 px-4 flex items-center rounded-t hover:bg-primary hover:text-white cursor-pointer border-b"
                  @click="editNote"
                >
                  <svg
                    class="fill-current w-4 h-4 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>{{ $t("edit") }}</span>
                </li>
                <li
                  class="py-2 px-4 flex items-center rounded-b hover:bg-primary hover:text-white cursor-pointer"
                  @click="deleteNote"
                >
                  <svg
                    class="fill-current w-4 h-4 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>{{ $t("delete") }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="p-4 rounded bg-gray-100 mt-1" v-if="details.dish.comment && !textareaIsActive">
            <p class="mt-2">
              {{ details.dish.comment }}
            </p>
          </div>
          <button
            class="btn-primary mt-2"
            @click="toggleTextarea"
            v-show="!textareaIsActive && !details.dish.comment"
          >
            {{ $t("add-note") }}
          </button>
          <form
            action="post"
            v-show="textareaIsActive"
            @submit.prevent="saveNote"
          >
            <textarea
              autofocus
              class="block mt-2 w-full shadow-sm border border-gray-300 rounded-md px-2 py-2"
              name="note"
              id="note"
              v-model="note"
              cols="30"
              rows="10"
            ></textarea>
            <button type="submit" class="btn-primary mt-2">
              {{ $t("add-note") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "SidebarDetails",
  props: ["details"],
  emits: ["storeNote", "closeDetails", "removeDish"],
  setup(props, { emit }) {
    const textareaIsActive = ref(false);
    const note = ref("");
    const showDropdown = ref(false);

    const toggleTextarea = () =>
      (textareaIsActive.value = !textareaIsActive.value);

    const toggleDropdown = () => (showDropdown.value = !showDropdown.value);

    const saveNote = () => {
      toggleTextarea();
      emit("storeNote", { note: note.value });
      note.value = "";
    };

    const editNote = () => {
      note.value = 'dfdffsd'
      toggleTextarea();
      toggleDropdown();
    };

    const deleteNote = () => {
      toggleDropdown();
      note.value = "";
      emit("storeNote", { note: null });
    }

    const removeFromDiary = () =>
      emit("removeDish", props.details.day, props.details.timeAtDay);

    return {
      textareaIsActive,
      note,
      toggleTextarea,
      toggleDropdown,
      editNote,
      saveNote,
      deleteNote,
      removeFromDiary,
      showDropdown,
    };
  },
};
</script>
