<template>
  <div
    class="fixed top-0 w-full h-screen bg-black bg-opacity-25 z-45"
    aria-hidden="true"
  >
    <div class="md:w-2/3 h-full relative" @click="$emit('closeFinder')"></div>
    <div
      class="fixed top-0 right-0 z-45 h-screen w-full md:w-1/3 bg-white border-solid border-t-4 border-primary p-8"
    >
      <div>
        <svg
          class="w-6 h-6 hover:text-red-200 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="$emit('closeFinder')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div class="mt-10">
        <h1 class="text-4xl text-primary">{{ $t("search-dish") }}</h1>
        <form action="post" class="mt-6" @submit.prevent="selectDish(null)">
          <input
            type="text"
            id="dish"
            name="dish"
            v-model="dish"
            @keyup="search"
            @focus="search"
            autofocus
            placeholder="Suche nach einem Gericht ..."
            class="block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 flex-1"
          />
        </form>
      </div>
      <div class="flex flex-col mt-2" v-if="showMissingDish">
        <span
          class="cursor-pointer rounded text-gray-900 hover:text-primary flex items-center text-sm"
          @click="showCreateDish"
        >
          "{{ dish }}" {{ $t("add-lowercase") }} ...
        </span>
      </div>
      <div class="suggestions flex flex-col" v-if="showSuggestions">
        <span
          v-for="dish in suggestions"
          :key="dish.id"
          @click="selectDish(dish)"
          class="w-full px-4 py-2 cursor-pointer rounded mt-2"
          :class="[
            dish._id === selected._id
              ? 'bg-primary text-white'
              : 'bg-gray-400 hover:bg-primary text-white',
          ]"
        >
          {{ dish.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "SidebarSearch",
  props: ["plan", "showFinder"],
  emits: ["storeDish", "closeFinder"],
  setup(props, { emit }) {
    const dish = ref("");
    const selected = ref({});
    const suggestions = ref([]);
    const showSuggestions = ref(false);
    const showMissingDish = ref(false);

    const search = async () => {
      if (dish.value.length > 2) {
        const response = await window.axios.post("/dishes/search", {
          term: dish.value,
        });
        suggestions.value = response.data;
        selected.value = suggestions.value[0];

        if (suggestions.value.length > 0) {
          showSuggestions.value = true;
          showMissingDish.value = false;
        } else {
          showSuggestions.value = false;
          showMissingDish.value = true;
        }
      } else {
        showSuggestions.value = false;
        suggestions.value = [];
      }
    };

    const selectDish = (dish) => {
      if (dish === null) {
        dish = selected.value;
      }

      emit("storeDish", { dish });
    };

    const showCreateDish = () => {
      window.emitter.emit("showCreateDish", { dish: dish.value });
    };

    return {
      dish,
      suggestions,
      showSuggestions,
      showMissingDish,
      selected,
      search,
      selectDish,
      showCreateDish,
    };
  },
};
</script>
