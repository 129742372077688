<template>
  <Layout>
    <template v-slot:content>
      <SidebarAddDish
        v-if="showModal"
        :dish="newDish"
        @closeModal="toggleAddDish()"
      ></SidebarAddDish>
      <div
        class="h-auto md:h-full flex flex-col md:flex-col-reverse justify-between pb-2"
      >
        <div class="flex md:items-end justify-between p-4">
          <div
            class="left w-full md:w-1/3 flex items-center justify-center md:justify-start"
          >
            <span
              @click="prevPage(currentWeek - 1)"
              class="p-2 rounded-tl rounded-bl bg-gray-400 text-white hover:bg-primary cursor-pointer"
            >
              <svg
                class="fill-current w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <span class="p-2 bg-gray-400 text-white tracking-wide text-xs">
              {{ $t("week") }}: <strong>{{ currentWeek }}</strong>
            </span>
            <span
              @click="nextPage(currentWeek + 1)"
              class="p-2 rounded-tr rounded-br bg-gray-400 text-white hover:bg-primary cursor-pointer"
            >
              <svg
                class="fill-current w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div>
            <button
              class="bg-primary text-white px-4 py-2 rounded hover:bg-primary-light flex items-center"
              @click="publishToFeed"
            >
              <svg
                class="fill-current w-4 h-4 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M5 3a1 1 0 000 2c5.523 0 10 4.477 10 10a1 1 0 102 0C17 8.373 11.627 3 5 3z"
                />
                <path
                  d="M4 9a1 1 0 011-1 7 7 0 017 7 1 1 0 11-2 0 5 5 0 00-5-5 1 1 0 01-1-1zM3 15a2 2 0 114 0 2 2 0 01-4 0z"
                />
              </svg>
              <span class="text-sm">{{ $t('publish') }}</span>
            </button>
          </div>
        </div>
        <Diary :week="currentWeek" @paging="setConstraints"></Diary>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../Layouts/Layout.vue";
import SidebarAddDish from "../components/SidebarAddDish.vue";
import Diary from "../components/Diary.vue";
import { ref } from "vue";

export default {
  name: "Calendar",
  components: { Layout, SidebarAddDish, Diary },
  setup() {
    const showModal = ref(false);
    const currentWeek = ref(window.dayjs().week());
    const prev = ref(true);
    const next = ref(true);
    const newDish = ref({
      name: "",
      source: "",
      selectedCategories: [],
    });

    window.emitter.on("showCreateDish", ({ dish }) => {
      toggleAddDish();
      newDish.value.name = dish;
    });

    window.emitter.on(
      "dish-created",
      () =>
        (newDish.value = {
          name: "",
          source: "",
          selectedCategories: [],
        })
    );

    const publishToFeed = () => {
      window.emitter.emit("publish-to-feed");
    }

    const nextPage = (week) => {
      if (!next.value) {
        return false;
      } else {
        currentWeek.value = week;
      }
    };
    const prevPage = (week) => {
      if (!prev.value) {
        return false;
      } else {
        currentWeek.value = week;
      }
    };
    const toggleAddDish = () => (showModal.value = !showModal.value);
    const setConstraints = (data) => {
      prev.value = data.prev;
      next.value = data.next;
    };

    return {
      showModal,
      currentWeek,
      newDish,
      nextPage,
      prevPage,
      toggleAddDish,
      setConstraints,
      publishToFeed
    };
  },
};
</script>
