<template>
  <div>
    <SidebarSearch
      :plan="editPlan"
      @closeFinder="toggleFinder"
      @storeDish="addToDiary"
      v-if="showFinder"
    ></SidebarSearch>
    <SidebarDetails
      :details="activeDish"
      @closeDetails="toggleDetails"
      @storeNote="addNoteToDiary"
      @removeDish="removeFromDiary"
      v-if="showDetails"
    ></SidebarDetails>
    <SidebarPublish
      :user="user"
      :plan="plan"
      :week="week"
      @closeModal="togglePublishSidebar"
      v-if="showPublishSidebar"
    ></SidebarPublish>
    <div class="md:mt-6 md:flex">
      <div class="hidden md:block">
        <div class="border-b py-5 text-lg">&nbsp;</div>
        <div class="border-r">
          <div class="py-5 border-b" v-if="meals.includes('breakfast')">
            <div class="min-h-full h-28 flex items-center justify-center">
              <span
                class="px-4 py-1 tracking-wide text-white rounded text-xs transform -rotate-90 bg-primary"
                >{{ $t("breakfast") }}</span
              >
            </div>
          </div>
          <div class="py-5 border-b" v-if="meals.includes('lunch')">
            <div class="min-h-full h-28 flex items-center justify-center">
              <span
                class="px-4 py-1 tracking-wide text-white rounded text-xs transform -rotate-90 bg-primary"
                >{{ $t("lunch") }}</span
              >
            </div>
          </div>
          <div class="py-5 border-b" v-if="meals.includes('dinner')">
            <div class="min-h-full h-28 flex items-center justify-center">
              <span
                class="px-4 py-1 tracking-wide text-white rounded text-xs transform -rotate-90 bg-primary"
                >{{ $t("dinner") }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="md:flex md:flex-1">
        <div class="md:flex-1" v-for="(plan, day, dayIndex) in plan" :key="day">
          <h4
            class="text-lg tracking-wider border-b py-5 pl-4"
            :class="today !== dayIndex ? 'text-gray-500' : 'text-primary'"
          >
            {{ $t(day) }} {{ dateOfDay(dayIndex) }}
          </h4>
          <div
            v-for="(dish, index) in plan"
            :key="index"
            v-show="meals.includes(dish.timeAtDay)"
            class="py-4 pl-4 pr-4 md:py-5 border-b md:pl-3 md:pr-0 bg-white"
          >
            <div
              v-if="dish.dish"
              class="rounded-lg p-2 min-h-full h-28 border border-primary shadow-lg text-gray-900 cursor-pointer flex flex-col justify-between"
              @click="
                toggleDetails(dish.timeAtDay, day, dateOfDay(index), dish)
              "
            >
              <span class="text-xl md:text-base">{{ dish.dish.name }}</span>
              <div class="flex items-end">
                <div class="flex items-center">
                  <span
                    v-for="category in dish.dish.categories"
                    :title="category.name"
                    :key="category._id"
                    class="h-3 w-3 p-1 rounded-full mr-1"
                    :style="`background: ${category.color}`"
                  ></span>
                </div>
                <span v-show="dish.comment" class="w-full flex justify-end">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              v-else
              class="rounded-lg p-2 min-h-full h-28 card-empty flex items-center justify-center"
            >
              <span
                class="btn-default"
                @click="toggleFinder(day, dish.timeAtDay)"
              >
                {{ $t("plan") }}
              </span>
            </div>
          </div>
        </div>
        <div class="hidden md:block w-3">
          <div class="py-5 text-lg border-b">&nbsp;</div>
          <div class="py-5 border-b">
            <div class="min-h-full h-28">&nbsp;</div>
          </div>
          <div class="py-5 border-b">
            <div class="min-h-full h-28">&nbsp;</div>
          </div>
          <div class="py-5 border-b">
            <div class="min-h-full h-28">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, watch, ref, computed } from "vue";
import SidebarSearch from "./SidebarSearch.vue";
import SidebarDetails from "./SidebarDetails.vue";
import SidebarPublish from "./SidebarPublish.vue";
import { useRouter } from "vue-router";

export default {
  name: "Diary",
  components: { SidebarSearch, SidebarDetails, SidebarPublish },
  props: ["week"],
  emits: ["paging"],
  setup(props, { emit }) {
    const diaries = ref([]);
    const meals = ref([]);
    const plan = ref({});
    const showFinder = ref(false);
    const showDetails = ref(false);
    const editPlan = ref({});
    const activeDish = ref({});
    const showPublishSidebar = ref(false);
    const user = ref(JSON.parse(window.localStorage.getItem("user")));
    const router = useRouter();

    onMounted(async () => {
      diaries.value = await getDiaries();
      plan.value = diaries.value.find(
        (diary) => diary.week === window.dayjs().week()
      ).diary;
      meals.value = JSON.parse(window.localStorage.getItem("meals"));
    });

    watch(
      () => props.week,
      (newWeek) => setActiveDiary(newWeek)
    );

    window.emitter.on("updateMeals", ({ meals }) => updateMeals(meals));
    window.emitter.on("dish-created", ({ dish }) => addToDiary({ dish }));
    window.emitter.on("publish-to-feed", () => togglePublishSidebar());

    const getDiaries = async () => {
      try {
        const { data } = await window.axios.get("/diaries");
        return data;
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: 'error',
            type: 'error',
          });
        }
      }
    };

    const setActiveDiary = (newWeek) => {
      const weeks = diaries.value.reduce((carry, diary) => {
        return [...carry, diary.week];
      }, []);

      weeks.sort(function (a, b) {
        return a - b;
      });

      if (newWeek === weeks[0]) {
        emit("paging", { prev: false, next: true });
      } else if (newWeek === weeks[weeks.length - 1]) {
        emit("paging", { prev: true, next: false });
      } else {
        emit("paging", { prev: true, next: true });
      }

      const weekData = diaries.value.find((diary) => diary.week === newWeek);
      plan.value = weekData.diary;
    };

    const toggleFinder = (day, timeAtDay) => {
      showFinder.value = !showFinder.value;
      editPlan.value = { day, timeAtDay };
    };

    const toggleDetails = (timeAtDay, day, date, dish) => {
      showDetails.value = !showDetails.value;
      activeDish.value = { timeAtDay, day, date, dish };
      editPlan.value = { day, timeAtDay };
    };

    const togglePublishSidebar = async () =>
      (showPublishSidebar.value = !showPublishSidebar.value);

    const updateDiary = async (day, timeAtDay, dishId) => {
      const diary = diaries.value.find((diary) => diary.week === props.week);
      const response = await window.axios.patch(`/diaries/${diary._id}`, {
        day,
        timeAtDay,
        dishId,
      });

      window.emitter.emit("toggleFlash", {
        message: response.data.message,
        type: response.data.type,
      });
    };

    const updateNote = async (day, timeAtDay, comment) => {
      const diary = diaries.value.find((diary) => diary.week === props.week);
      const response = await window.axios.patch(
        `/diaries/${diary._id}/comment`,
        {
          day,
          timeAtDay,
          comment,
        }
      );

      window.emitter.emit("toggleFlash", {
        message: response.data.message,
        type: response.data.type,
      });
    };

    const updateMeals = (newMeals) => (meals.value = JSON.parse(newMeals));

    const addToDiary = async ({ dish }) => {
      plan.value[editPlan.value.day].map((times) => {
        if (times.timeAtDay === editPlan.value.timeAtDay) {
          times.dish = dish;
          return times;
        }

        return times;
      });

      await updateDiary(editPlan.value.day, editPlan.value.timeAtDay, dish._id);
      editPlan.value = {};
      toggleFinder(null, null);
    };

    const addNoteToDiary = async ({ note }) => {
      plan.value[editPlan.value.day].map((times) => {
        if (times.timeAtDay === editPlan.value.timeAtDay) {
          times.comment = note;
          return times;
        }

        return times;
      });

      await updateNote(editPlan.value.day, editPlan.value.timeAtDay, note);
      editPlan.value = {};
    };

    const removeFromDiary = async (day, timeAtDay) => {
      plan.value[day].map((times) => {
        if (times.timeAtDay === timeAtDay) {
          times.dish = null;
          return times;
        }

        return times;
      });

      toggleDetails();

      await updateDiary(day, timeAtDay, null);
    };

    const dateOfDay = (index) =>
      window.dayjs().week(props.week).weekday(index).format("DD.MM.");

    const today = computed(() => window.dayjs().isoWeekday() - 1);

    return {
      plan,
      meals,
      showFinder,
      showDetails,
      showPublishSidebar,
      editPlan,
      user,
      activeDish,
      toggleFinder,
      toggleDetails,
      togglePublishSidebar,
      addToDiary,
      addNoteToDiary,
      removeFromDiary,
      dateOfDay,
      today,
    };
  },
};
</script>
